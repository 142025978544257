<template>
  <b-modal
    :visible="show_book_coupon_class_modal"
    :title="$t('i_want_to') + $t('class.book')"
    centered
    header-text-variant="light"
    header-bg-variant="primary"
    :ok-disabled="booking_application.class_time == null"
    :ok-title="$t('class.book')"
    :cancel-title="$t('cancel')"
    no-close-on-backdrop
    @hidden="hideBookCouponClassmodal"
    @ok="bookCouponClass"
  >
    <b-row class="text-center">
      <b-col><h2>{{ booking_application.title }}商務英文溝通力 – 書信電話篇 –</h2></b-col>
    </b-row>
    <b-row class="text-center">
      <b-col><h2>{{ booking_application.subtitle }}Intermediate Business English A Formal Email 1</h2></b-col>
    </b-row>
    <div class="are-clsapc-input">
      <div class="row blk-clsapc-input">

        <div class="col-12 panel-clsapc-input mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ $t('consultant') }}</span>
            </div>
            <b-form-select
              v-model="booking_application.consultant"
              v-validate="'required'"
              required
              name="consultant"
              :options="consultant"
            />
          </div>
        </div>

        <div class="col-12 panel-clsapc-input mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ $t('date') }}</span>
            </div>
            <!-- <datepicker v-model="booking_application.date"
                                    :bootstrap-styling="true" format="yyyy-MM-dd" :placeholder="$t('select_date')"
                                    :disabled="disabled" :open-date="open_date">
                        </datepicker> -->
            <datepicker
              v-model="booking_application.date"
              :bootstrap-styling="true"
              format="yyyy-MM-dd"
              :placeholder="$t('select')+$t('date')"
            />
          </div>
        </div>
        <div v-show="true" class="col-12 panel-clsapc-input">
          <b-alert
            v-if="true"
            show
            variant="danger"
            class="mb-0"
          >
            {{ $t('class.time_without_consultant_alert') }}
          </b-alert>
          <div v-else class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ $t('time') }}</span>
            </div>

            <b-form-select
              v-model="booking_application.class_time"
              v-validate="'required'"
              required
              name="class_time"
              :options="consultant_timelist"
            >
              <template slot="first">
                <option :value="null" disabled="disabled">{{ $t('select_time') }}</option>
              </template>
            </b-form-select>
            <br>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import datepicker from 'vuejs-datepicker';
export default {
  name: 'BookCouponClass',

  components: {
    datepicker,
  },

  props: {
    // eslint-disable-next-line
    show_book_coupon_class_modal: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },

  data() {
    return {
      consultant: [
        { text: this.$t('please') + this.$t('select') + this.$t('consultant'), value: null, disabled: true },
        { text: this.$t('hbt'), value: 'HBT' },
        { text: this.$t('hbf'), value: 'HBF' },
      ],

      booking_application: '',

      consultant_timelist: [],
    };
  },

  created() {},

  methods: {
    bookCouponClass() {},

    hideBookCouponClassmodal() {
      this.$emit('closeBookCouponClassModal', false);
    },
  },
};
</script>

<style lang="scss">
  .input-group{
    flex-wrap: unset !important;
  }

  .vdp-datepicker{
    width: 100%;
  }

  .are-clsapc-input {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    color: red;
    > .blk-clsapc-input {
      width: 92%;
    }

    .btn-add-ca {
        align-self: flex-start;
    }
  }

  @media screen and(max-width:768px) {
    .are-clsapc-input {
      > .blk-clsapc-input {
        width: 102%;
      }
    }
  }
</style>
