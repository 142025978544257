var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head d-flex justify-content-between"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v("WuShare " + _vm._s(_vm.$t('notes_for_students')))])]), _c('div', {
    staticClass: "wuwow-card-body notice-card-body"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "notice-title"
  }, [_vm._v(_vm._s(_vm.$t('class.booking_instructions')) + " | ")]), _c('span', {
    staticClass: "notice-subtitle"
  }, [_vm._v(_vm._s(_vm.$t('class.you_need_to_know_before_book')) + "!")])]), _c('ol', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.applyNotice == 'lectures',
      expression: "applyNotice == 'lectures'"
    }],
    staticClass: "notice-text booking-notice mb-5"
  }, [_c('li', {
    staticClass: "mb-1"
  }, [_vm._v("百家書苑每堂課程50分鐘，課程前兩分鐘開放進入教室")]), _c('li', {
    staticClass: "mb-1"
  }, [_vm._v(" 直播型態的單向式講座課程，學員可於聊天室使用文字訊息提問，問題皆由助教以文字回覆，但因課程的即時性，無法保證所有問題皆能即時回覆。 ")]), _c('li', {
    staticClass: "mb-1"
  }, [_vm._v(" 課程結束後提供錄影檔，預約未出席者也可於課後下載檔案(影片檔不含聊天室文字內容)。 ")]), _c('li', {
    staticClass: "mb-1"
  }, [_vm._v("課程教材可於課程開始前下載，不得作商業用途使用。")])]), _c('ol', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.applyNotice != 'lectures',
      expression: "applyNotice != 'lectures'"
    }],
    staticClass: "notice-text booking-notice mb-5"
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.applyNotice != 'suit',
      expression: "applyNotice != 'suit'"
    }],
    staticClass: "mb-1"
  }, [_vm._v(" 完成章節內容及測驗後，即可預訂本章節的真人課程 ")]), _c('li', {
    staticClass: "mb-1"
  }, [_vm._v(" 每堂課程25分鐘，以一對一方式進行，你可以詢問顧問任何關於課堂的問題，或是其他英文學習的延伸，顧問都將親切地為您解答。 ")]), _c('li', {
    staticClass: "mb-1"
  }, [_vm._v(" 本課程由WUWOW線上英文提供專業師資，請依自身能力評估選擇支援中英文的「雙語顧問」或是純英文的「外籍顧問」。 ")]), _vm.applyNotice == 'suit' ? _c('li', {
    staticClass: "mb-1"
  }, [_vm._v(" 最快訂上課日期：自訂課當下計算，3天後之課程時段。"), _c('br'), _vm._v("每日可預約時段：每日早上 09:00 至夜間 00:00，（一天共31個時段）。 ")]) : _c('li', {
    staticClass: "mb-1"
  }, [_vm._v(" 最快訂上課日期：自訂課當下計算，5天後之課程時段。"), _c('br'), _vm._v("每日可預約時段：每日早上 09:00 至夜間 00:00，（一天共31個時段）。 ")]), _c('li', {
    staticClass: "mb-1"
  }, [_vm._v("每日同時段可預約之顧問有上限，熱門時段請提早訂課以免額滿！")])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "notice-title"
  }, [_vm._v(_vm._s(_vm.$t('class.class_instructions')) + " | ")]), _c('span', {
    staticClass: "notice-subtitle"
  }, [_vm._v(_vm._s(_vm.$t('class.you_need_to_know_after_book')) + "！")])]), _c('div', {
    staticClass: "wuwow-accordion mb-5"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.faq1j",
      modifiers: {
        "faq1j": true
      }
    }],
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_vm._v(" 1.課程開始前請務必事先安裝WUWOW Online Learning system "), _c('span', {
    staticClass: "fas icn-arrow"
  })]), _c('b-collapse', {
    attrs: {
      "id": "faq1j",
      "accordion": "analysia-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_c('a', {
    attrs: {
      "href": "https://docs.google.com/presentation/d/1Yn5KzYCPV-7MOBsjh3qWq5TU76HGWdAU7_y23sgj99U/edit?usp=sharing",
      "target": "_blank"
    }
  }, [_vm._v(" Windows OS (微軟) 電腦安裝流程 ")]), _c('br'), _c('a', {
    attrs: {
      "href": "https://docs.google.com/presentation/d/14ymU9-TgOLmUULoM43emy49kecqcnPe1RfhZn2Gu-vg/edit?usp=sharing",
      "target": "_blank"
    }
  }, [_vm._v(" Mac OS (蘋果) 電腦安裝流程 ")]), _c('br'), _c('a', {
    attrs: {
      "href": "https://docs.google.com/presentation/d/1QGt8GUgvjvBkOk6kYEWJuZdfpN_QMkObeKOVuj6Mz90/edit?usp=sharing",
      "target": "_blank"
    }
  }, [_vm._v(" Android OS (安卓) 行動裝置安裝流程 ")]), _c('br'), _c('a', {
    attrs: {
      "href": "https://docs.google.com/presentation/d/1yirDQiZAcMonjz6sCvHj_1E74vW8fMddXiJKy3Dz_mI/edit?usp=sharing",
      "target": "_blank"
    }
  }, [_vm._v(" iOS (蘋果) 行動裝置安裝流程 ")]), _c('br'), _c('br'), _vm._v(" 提醒您，一般上課時，按照官網進入教室流程即可，"), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v("無須開啟程式")]), _c('br'), _vm._v(" ✨如有任何安裝問題，歡迎您聯繫我們 電話：`"), _c('a', {
    attrs: {
      "href": "tel:${companyPhone}"
    }
  }, [_vm._v("`" + _vm._s(_vm.companyPhone))]), _vm._v(" 線上客服Line : "), _c('a', {
    attrs: {
      "href": "http://line.me/ti/p/~wuwowcs"
    }
  }, [_vm._v("wuwowcs")]), _vm._v("✨ ")])], 1)], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.faq1b",
      modifiers: {
        "faq1b": true
      }
    }],
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_vm._v(" 2.預定時間到的時候，我該如何開始上課？ "), _c('span', {
    staticClass: "fas icn-arrow"
  })]), _c('b-collapse', {
    attrs: {
      "id": "faq1b",
      "accordion": "analysia-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_vm._v(" 若您已安裝WUWOW Online Learning system，你可以於 "), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v("課程時間2分鐘前至課程時間3分鐘後")]), _vm._v("之間"), _c('br'), _vm._v(" 點選"), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v("「開始上課」")]), _vm._v(" > 並點選 "), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v(" 「進入教室」 ")]), _vm._v("，若超過此時區間將無法進入教室。"), _c('br'), _vm._v(" (若課程時間為晚上20:00，則可進入教室之時間為19:58至20:03) ")])], 1)], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.faq1c",
      modifiers: {
        "faq1c": true
      }
    }],
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_vm._v(" 3.我可以取消課程嗎？ "), _c('span', {
    staticClass: "fas icn-arrow"
  })]), _c('b-collapse', {
    attrs: {
      "id": "faq1c",
      "accordion": "analysia-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_vm._v(" 若您已完成訂課，您可以於課程開始的"), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v("15分鐘前")]), _vm._v("自由取消，若超過此時間則無法取消訂課且無法重新預訂本章節的一對一真人課程。 ")])], 1)], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.faq2a",
      modifiers: {
        "faq2a": true
      }
    }],
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_vm._v(" 4.課程上完後，可以下載課程錄影檔嗎？ "), _c('span', {
    staticClass: "fas icn-arrow"
  })]), _c('b-collapse', {
    attrs: {
      "id": "faq2a",
      "accordion": "analysia-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_vm._v(" 課程結束後的"), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v("七天內")]), _vm._v("可以自由下載課程的錄影檔，請在時限內將課程檔案下載保存起來喔！ （錄影檔將於"), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v("課後24小時內")]), _vm._v("上架於「"), _c('span', {
    staticClass: "txt-mark"
  }, [_vm._v("學習軌跡")]), _vm._v("」中） ")])], 1)], 1)], 1), _vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-4 text-center"
  }, [_c('a', {
    staticClass: "wushare-agreement",
    attrs: {
      "href": "https://wushare.tw/course-paying-user-agreement/",
      "target": "_blank"
    }
  }, [_vm._v(" < WuShare 網際網路課程購買暨服務契約 > ")])]);

}]

export { render, staticRenderFns }