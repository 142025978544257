<template>
  <div class="coupon-class reg-clsapc">
    <server-time-clock />

    <div class="wuwow-card">
      <div class="wuwow-card-head">
        <h4 class="wuwow-card-title">{{ $t('coupon.exchanged_coupon_class_title') }}</h4>
      </div>

      <div class="wuwow-card-body">
        <table class="table table-hover table-clsapc-record text-center">
          <thead class="thead-default">
            <tr>
              <th>{{ $t('class.name') }}</th>
              <th>{{ $t('class.type') }}</th>
              <th>{{ $t('class.lesson') }}{{ $t('time') }}</th>
              <th>{{ $t('operate') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a>商務英文溝通力 – 書信電話篇 – Intermediate Business English A Formal Email 1</a>
                <!-- <a v-if="item.material.file" :href="item.material.file">{{item.coupon_product.title}}<br>{{item.coupon_product_item.title}}</a>
                                <span v-else class="material-link">{{item.coupon_product.title}}<br>{{item.coupon_product_item.title}}</span> -->
              </td>
              <td>1 on 1兌換家教班</td>
              <td />
              <!-- <td>{{item.material_title}}</td> -->
              <td>
                <!-- 百家下載錄影檔 -->
                <!-- <b-button class="btn" variant="primary" v-if="showDownloadButton(item) " @click="downloadClassVideo(item.video.link)">{{ $t('download_class') }}</b-button> -->
                <b-button
                  class="btn"
                  variant="outline-danger"
                  @click="showCancelClassAlert()"
                >
                  {{ $t('class.cancel_booked') }}
                </b-button>
                <b-button class="btn" variant="primary" @click="openBookCouponClass()">{{ $t('i_want_to') }}{{ $t('class.book') }}</b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="wuwow-card-footer">
        <b-pagination
          align="center"
          :total-rows="5"
          :per-page="3"
          @input="changePage()"
        />
      </div>

      <b-modal
        id="modal-center"
        v-model="show_cancle_class_alert"
        :title="$t('class.cancel_class_modal_title') + '?'"
        header-bg-variant="primary"
        header-text-variant="light"
        ok-title="確定"
        cancel-title="取消"
        centered
        @ok="cancelClass"
      >
        <p class="my-4 text-center" style="font-size: 18px" />
      </b-modal>

      <book-coupon-class-modal
        v-model="show_book_coupon_class_modal"
        :show_book_coupon_class_modal="show_book_coupon_class_modal"
        @closeBookCouponClassModal="closeBookCouponClassModal"
      />
      <book-coupon-academy-class-modal
        v-model="show_book_coupon_academy_class_modal"
        :show_book_coupon_academy_class_modal="show_book_coupon_academy_class_modal"
        @closeBookCouponAcademyClassModal="closeBookCouponAcademyClassModal"
      />
      <!-- <booking-coupon-academy-class v-show="show_booking_academy_modal" :bookingInfo="bookingInfo" :showBookingAcademyModal="show_booking_academy_modal" :currentPage="next_currentPage" @closeBookingAcademyModal='closeBookingAcademyModal'></booking-coupon-academy-class> -->
    </div>
    <wushare-coupon-class-notice />
  </div>
</template>

<script>
import ServerTimeClock from '@/components/serverTimeClock';
import WushareCouponClassNotice from '@/components/vip/coupon/wushareCouponClassNotice';
import BookCouponClassModal from '@/components/modal/coupon/bookCouponClass';
import BookCouponAcademyClassModal from '@/components/modal/coupon/bookCouponAcademyClass';
export default {
  name: 'CouponClass',

  components: {
    ServerTimeClock,
    WushareCouponClassNotice,
    BookCouponClassModal,
    BookCouponAcademyClassModal,
  },

  data() {
    return {
      show_cancle_class_alert: false,

      show_book_coupon_class_modal: false,

      show_book_coupon_academy_class_modal: false,
    };
  },

  created() {},

  methods: {
    changePage() {},

    showCancelClassAlert() {
      this.show_cancle_class_alert = true;
    },

    cancelClass() {
      this.show_cancle_class_alert = false;
    },

    openBookCouponClass() {
      this.show_book_coupon_class_modal = true;
    },

    closeBookCouponClassModal(res) {
      this.show_book_coupon_class_modal = res;
    },

    openBookCouponAcademyClass() {
      this.show_book_coupon_academy_class_modal = true;
    },

    closeBookCouponAcademyClassModal(res) {
      this.show_book_coupon_academy_class_modal = res;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vips/coupon';
</style>
