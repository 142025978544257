var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "coupon-class reg-clsapc"
  }, [_c('server-time-clock'), _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.$t('coupon.exchanged_coupon_class_title')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('table', {
    staticClass: "table table-hover table-clsapc-record text-center"
  }, [_c('thead', {
    staticClass: "thead-default"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('class.name')))]), _c('th', [_vm._v(_vm._s(_vm.$t('class.type')))]), _c('th', [_vm._v(_vm._s(_vm.$t('class.lesson')) + _vm._s(_vm.$t('time')))]), _c('th', [_vm._v(_vm._s(_vm.$t('operate')))])])]), _c('tbody', [_c('tr', [_vm._m(0), _c('td', [_vm._v("1 on 1兌換家教班")]), _c('td'), _c('td', [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        return _vm.showCancelClassAlert();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('class.cancel_booked')) + " ")]), _c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.openBookCouponClass();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('i_want_to')) + _vm._s(_vm.$t('class.book')))])], 1)])])])]), _c('div', {
    staticClass: "wuwow-card-footer"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": 5,
      "per-page": 3
    },
    on: {
      "input": function ($event) {
        return _vm.changePage();
      }
    }
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-center",
      "title": _vm.$t('class.cancel_class_modal_title') + '?',
      "header-bg-variant": "primary",
      "header-text-variant": "light",
      "ok-title": "確定",
      "cancel-title": "取消",
      "centered": ""
    },
    on: {
      "ok": _vm.cancelClass
    },
    model: {
      value: _vm.show_cancle_class_alert,
      callback: function ($$v) {
        _vm.show_cancle_class_alert = $$v;
      },
      expression: "show_cancle_class_alert"
    }
  }, [_c('p', {
    staticClass: "my-4 text-center",
    staticStyle: {
      "font-size": "18px"
    }
  })]), _c('book-coupon-class-modal', {
    attrs: {
      "show_book_coupon_class_modal": _vm.show_book_coupon_class_modal
    },
    on: {
      "closeBookCouponClassModal": _vm.closeBookCouponClassModal
    },
    model: {
      value: _vm.show_book_coupon_class_modal,
      callback: function ($$v) {
        _vm.show_book_coupon_class_modal = $$v;
      },
      expression: "show_book_coupon_class_modal"
    }
  }), _c('book-coupon-academy-class-modal', {
    attrs: {
      "show_book_coupon_academy_class_modal": _vm.show_book_coupon_academy_class_modal
    },
    on: {
      "closeBookCouponAcademyClassModal": _vm.closeBookCouponAcademyClassModal
    },
    model: {
      value: _vm.show_book_coupon_academy_class_modal,
      callback: function ($$v) {
        _vm.show_book_coupon_academy_class_modal = $$v;
      },
      expression: "show_book_coupon_academy_class_modal"
    }
  })], 1), _c('wushare-coupon-class-notice')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('a', [_vm._v("商務英文溝通力 – 書信電話篇 – Intermediate Business English A Formal Email 1")])]);

}]

export { render, staticRenderFns }