var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "visible": _vm.show_book_coupon_academy_class_modal,
      "title": _vm.$t('booking_class'),
      "size": "lg",
      "centered": "",
      "header-text-variant": "light",
      "header-bg-variant": "primary",
      "hide-footer": true
    },
    on: {
      "hidden": _vm.hideBookCouponAcademyClassmodal
    }
  }, [_c('div', [_c('div', [_c('b-card', {
    staticClass: "academy-card overflow-hidden",
    attrs: {
      "no-body": "",
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "card-head d-flex align-items-center justify-content-center",
    attrs: {
      "lg": "6"
    }
  }), _c('b-col', {
    staticClass: "card-content",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card-body', [_c('h5', {
    staticClass: "lnk-classrooms-title mb-3",
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("記事本")]), _c('div', {
    staticClass: "card-text"
  }, [_c('span', {
    staticClass: "d-flex"
  }, [_c('i', {
    staticClass: "far fa-calendar-minus card-icon"
  }), _vm._v(_vm._s(_vm.$t('class_time')))]), _c('div', {
    staticClass: "card-text-detail"
  }, [_vm._v("2021/11/30 ~ 2021/12/31")])]), _c('div', {
    staticClass: "card-text"
  }, [_c('span', {
    staticClass: "d-flex"
  }, [_c('i', {
    staticClass: "fas fa-user-plus card-icon"
  }), _vm._v(_vm._s(_vm.$t('booking_class_time')) + " ")]), _c('div', {
    staticClass: "card-text-detail"
  }, [_vm._v("2021/11/30 ~ 2021/12/31")])]), _c('div', {
    staticClass: "card-text"
  }, [_c('span', {
    staticClass: "d-flex"
  }, [_c('i', {
    staticClass: "fas fa-chalkboard-teacher card-icon"
  }), _vm._v(" Jenny")])]), _c('b-row', [_c('b-col', {
    staticClass: "card-btn",
    attrs: {
      "lg": "12"
    }
  }, [true ? _c('a', {
    staticStyle: {
      "pointer-events": "none",
      "color": "#ccc"
    },
    attrs: {
      "href": "javascript:;"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.$t('class_full')))])], 1) : _c('div', [_c('a', {
    staticStyle: {
      "pointer-events": "none",
      "color": "#ccc"
    },
    attrs: {
      "href": "javascript:;"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('br'), _vm._v(" " + _vm._s(_vm.$t('join_class_alert')) + " ")])], 1), _c('b-button', {
    staticClass: "add-btn",
    attrs: {
      "pill": "",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.bookingAcademyClass();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-2"
  }), _vm._v(_vm._s(_vm.$t('join_class')))])], 1)])], 1)], 1)], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "reg-no-academy-talks"
  }, [_vm._v(" " + _vm._s(_vm.$t('no_class')) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }