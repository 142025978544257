var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "visible": _vm.show_book_coupon_class_modal,
      "title": _vm.$t('i_want_to') + _vm.$t('class.book'),
      "centered": "",
      "header-text-variant": "light",
      "header-bg-variant": "primary",
      "ok-disabled": _vm.booking_application.class_time == null,
      "ok-title": _vm.$t('class.book'),
      "cancel-title": _vm.$t('cancel'),
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hideBookCouponClassmodal,
      "ok": _vm.bookCouponClass
    }
  }, [_c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', [_c('h2', [_vm._v(_vm._s(_vm.booking_application.title) + "商務英文溝通力 – 書信電話篇 –")])])], 1), _c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', [_c('h2', [_vm._v(_vm._s(_vm.booking_application.subtitle) + "Intermediate Business English A Formal Email 1")])])], 1), _c('div', {
    staticClass: "are-clsapc-input"
  }, [_c('div', {
    staticClass: "row blk-clsapc-input"
  }, [_c('div', {
    staticClass: "col-12 panel-clsapc-input mb-3"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v(_vm._s(_vm.$t('consultant')))])]), _c('b-form-select', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "required": "",
      "name": "consultant",
      "options": _vm.consultant
    },
    model: {
      value: _vm.booking_application.consultant,
      callback: function ($$v) {
        _vm.$set(_vm.booking_application, "consultant", $$v);
      },
      expression: "booking_application.consultant"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12 panel-clsapc-input mb-3"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v(_vm._s(_vm.$t('date')))])]), _c('datepicker', {
    attrs: {
      "bootstrap-styling": true,
      "format": "yyyy-MM-dd",
      "placeholder": _vm.$t('select') + _vm.$t('date')
    },
    model: {
      value: _vm.booking_application.date,
      callback: function ($$v) {
        _vm.$set(_vm.booking_application, "date", $$v);
      },
      expression: "booking_application.date"
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: true,
      expression: "true"
    }],
    staticClass: "col-12 panel-clsapc-input"
  }, [true ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('class.time_without_consultant_alert')) + " ")]) : _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v(_vm._s(_vm.$t('time')))])]), _c('b-form-select', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "required": "",
      "name": "class_time",
      "options": _vm.consultant_timelist
    },
    model: {
      value: _vm.booking_application.class_time,
      callback: function ($$v) {
        _vm.$set(_vm.booking_application, "class_time", $$v);
      },
      expression: "booking_application.class_time"
    }
  }, [_c('template', {
    slot: "first"
  }, [_c('option', {
    attrs: {
      "disabled": "disabled"
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(_vm._s(_vm.$t('select_time')))])])], 2), _c('br')], 1)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }