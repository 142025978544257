<template>
  <b-modal
    :visible="show_book_coupon_academy_class_modal"
    :title="$t('booking_class')"
    size="lg"
    centered
    header-text-variant="light"
    header-bg-variant="primary"
    :hide-footer="true"
    @hidden="hideBookCouponAcademyClassmodal"
  >
    <div>
      <div>
        <b-card no-body class="academy-card overflow-hidden" md="12">
          <b-row no-gutters class="d-flex align-items-center justify-content-center">
            <b-col
              lg="6"
              class="card-head d-flex align-items-center justify-content-center"
            >
              <!-- <b-card-img :src="item.material.image" alt="Image" class="card-img rounded-0"></b-card-img>
                            <b-card-img :src="item.consultant.image" alt="Image" class="consultant-img"></b-card-img> -->
            </b-col>
            <b-col lg="6" class="card-content">
              <b-card-body>
                <h5 class="lnk-classrooms-title mb-3" style="color: red">記事本</h5>

                <div class="card-text">
                  <span class="d-flex"><i class="far fa-calendar-minus card-icon" />{{ $t('class_time') }}</span>
                  <div class="card-text-detail">2021/11/30 ~ 2021/12/31</div>
                </div>
                <div class="card-text">
                  <span
                    class="d-flex"
                  ><i class="fas fa-user-plus card-icon" />{{ $t('booking_class_time') }}
                  </span>
                  <div class="card-text-detail">2021/11/30 ~ 2021/12/31</div>
                </div>
                <div class="card-text">
                  <span class="d-flex"><i class="fas fa-chalkboard-teacher card-icon" /> Jenny</span>
                </div>

                <b-row>
                  <b-col lg="12" class="card-btn">
                    <a
                      v-if="true"
                      href="javascript:;"
                      style="pointer-events: none; color: #ccc"
                    >
                      <b-alert show variant="danger">{{ $t('class_full') }}</b-alert>
                    </a>
                    <div v-else>
                      <a href="javascript:;" style="pointer-events: none; color: #ccc">
                        <b-alert show variant="danger">
                          <br>
                          {{ $t('join_class_alert') }}
                        </b-alert>
                      </a>

                      <b-button
                        pill
                        variant="primary"
                        class="add-btn"
                        @click="bookingAcademyClass()"
                      ><i class="fa fa-plus mr-2" />{{ $t('join_class') }}</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <div class="reg-no-academy-talks">
      {{ $t('no_class') }}
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'BookCouponClass',

  components: {},

  props: {
    // eslint-disable-next-line
    show_book_coupon_academy_class_modal: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },

  data() {
    return {};
  },

  created() {},

  methods: {
    bookingAcademyClass() {},

    hideBookCouponAcademyClassmodal() {
      this.$emit('closeBookCouponAcademyClassModal', false);
    },
  },
};
</script>

<style lang="scss"></style>
