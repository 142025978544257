<template>
  <div class="wuwow-card">
    <div class="wuwow-card-head d-flex justify-content-between">
      <h4 class="wuwow-card-title">WuShare {{ $t('notes_for_students') }}</h4>
    </div>
    <div class="wuwow-card-body notice-card-body">
      <div class="mb-4">
        <span class="notice-title">{{ $t('class.booking_instructions') }} | </span>
        <span class="notice-subtitle">{{ $t('class.you_need_to_know_before_book') }}!</span>
      </div>
      <ol v-show="applyNotice == 'lectures'" class="notice-text booking-notice mb-5">
        <li class="mb-1">百家書苑每堂課程50分鐘，課程前兩分鐘開放進入教室</li>
        <li class="mb-1">
          直播型態的單向式講座課程，學員可於聊天室使用文字訊息提問，問題皆由助教以文字回覆，但因課程的即時性，無法保證所有問題皆能即時回覆。
        </li>
        <li class="mb-1">
          課程結束後提供錄影檔，預約未出席者也可於課後下載檔案(影片檔不含聊天室文字內容)。
        </li>
        <li class="mb-1">課程教材可於課程開始前下載，不得作商業用途使用。</li>
      </ol>
      <ol v-show="applyNotice != 'lectures'" class="notice-text booking-notice mb-5">
        <li v-show="applyNotice != 'suit'" class="mb-1">
          完成章節內容及測驗後，即可預訂本章節的真人課程
        </li>
        <li class="mb-1">
          每堂課程25分鐘，以一對一方式進行，你可以詢問顧問任何關於課堂的問題，或是其他英文學習的延伸，顧問都將親切地為您解答。
        </li>
        <li class="mb-1">
          本課程由WUWOW線上英文提供專業師資，請依自身能力評估選擇支援中英文的「雙語顧問」或是純英文的「外籍顧問」。
        </li>
        <li v-if="applyNotice == 'suit'" class="mb-1">
          最快訂上課日期：自訂課當下計算，3天後之課程時段。<br>每日可預約時段：每日早上
          09:00 至夜間 00:00，（一天共31個時段）。
        </li>
        <li v-else class="mb-1">
          最快訂上課日期：自訂課當下計算，5天後之課程時段。<br>每日可預約時段：每日早上
          09:00 至夜間 00:00，（一天共31個時段）。
        </li>
        <li class="mb-1">每日同時段可預約之顧問有上限，熱門時段請提早訂課以免額滿！</li>
      </ol>
      <div class="mb-4">
        <span class="notice-title">{{ $t('class.class_instructions') }} | </span>
        <span class="notice-subtitle">{{ $t('class.you_need_to_know_after_book') }}！</span>
      </div>
      <div class="wuwow-accordion mb-5">
        <b-card no-body>
          <b-card-header v-b-toggle.faq1j header-tag="header" role="tab">
            1.課程開始前請務必事先安裝WUWOW Online Learning system
            <span class="fas icn-arrow" />
          </b-card-header>
          <b-collapse id="faq1j" accordion="analysia-accordion" role="tabpanel">
            <b-card-body>
              <a
                href="https://docs.google.com/presentation/d/1Yn5KzYCPV-7MOBsjh3qWq5TU76HGWdAU7_y23sgj99U/edit?usp=sharing"
                target="_blank"
              >
                Windows OS (微軟) 電腦安裝流程
              </a>
              <br>
              <a
                href="https://docs.google.com/presentation/d/14ymU9-TgOLmUULoM43emy49kecqcnPe1RfhZn2Gu-vg/edit?usp=sharing"
                target="_blank"
              >
                Mac OS (蘋果) 電腦安裝流程
              </a>
              <br>
              <a
                href="https://docs.google.com/presentation/d/1QGt8GUgvjvBkOk6kYEWJuZdfpN_QMkObeKOVuj6Mz90/edit?usp=sharing"
                target="_blank"
              >
                Android OS (安卓) 行動裝置安裝流程
              </a>
              <br>
              <a
                href="https://docs.google.com/presentation/d/1yirDQiZAcMonjz6sCvHj_1E74vW8fMddXiJKy3Dz_mI/edit?usp=sharing"
                target="_blank"
              >
                iOS (蘋果) 行動裝置安裝流程
              </a>
              <br><br>
              提醒您，一般上課時，按照官網進入教室流程即可，<span class="txt-mark">無須開啟程式</span><br>
              ✨如有任何安裝問題，歡迎您聯繫我們 電話：`<a href="tel:${companyPhone}">`{{ companyPhone }}</a>
              線上客服Line : <a href="http://line.me/ti/p/~wuwowcs">wuwowcs</a>✨
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-card-header v-b-toggle.faq1b header-tag="header" role="tab">
            2.預定時間到的時候，我該如何開始上課？
            <span class="fas icn-arrow" />
          </b-card-header>
          <b-collapse id="faq1b" accordion="analysia-accordion" role="tabpanel">
            <b-card-body>
              若您已安裝WUWOW Online Learning system，你可以於
              <span class="txt-mark">課程時間2分鐘前至課程時間3分鐘後</span>之間<br>
              點選<span class="txt-mark">「開始上課」</span> > 並點選
              <span
                class="txt-mark"
              >
                「進入教室」
              </span>，若超過此時區間將無法進入教室。<br>
              (若課程時間為晚上20:00，則可進入教室之時間為19:58至20:03)
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-card-header v-b-toggle.faq1c header-tag="header" role="tab">
            3.我可以取消課程嗎？
            <span class="fas icn-arrow" />
          </b-card-header>
          <b-collapse id="faq1c" accordion="analysia-accordion" role="tabpanel">
            <b-card-body>
              若您已完成訂課，您可以於課程開始的<span class="txt-mark">15分鐘前</span>自由取消，若超過此時間則無法取消訂課且無法重新預訂本章節的一對一真人課程。
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-card-header v-b-toggle.faq2a header-tag="header" role="tab">
            4.課程上完後，可以下載課程錄影檔嗎？
            <span class="fas icn-arrow" />
          </b-card-header>
          <b-collapse id="faq2a" accordion="analysia-accordion" role="tabpanel">
            <b-card-body>
              課程結束後的<span class="txt-mark">七天內</span>可以自由下載課程的錄影檔，請在時限內將課程檔案下載保存起來喔！
              （錄影檔將於<span class="txt-mark">課後24小時內</span>上架於「<span class="txt-mark">學習軌跡</span>」中）
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="mb-4 text-center">
        <a
          class="wushare-agreement"
          href="https://wushare.tw/course-paying-user-agreement/"
          target="_blank"
        >
          &lt; WuShare 網際網路課程購買暨服務契約 &gt;
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import companyConstant from '@/constants/company';
export default {
  name: 'WushareCouponClassNotice',

  components: {},

  data() {
    return {
      applyNotice: 'suit',
      companyPhone: companyConstant.INFO.CONTACT_PHONE,
    };
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss"></style>
